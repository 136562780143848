import React from 'react';
import './Contact.css';

const Contact = () => {
  return (
    <div className="contact-container">
      <h2 className="contact-title">Contact Me</h2>
      <p className="contact-email">For inquiries, please contact me at:</p>
      <a href="mailto:jerry@jerryevanoff.com" className="contact-email-link">
        jerry@jerryevanoff.com
      </a>
    </div>
  );
};

export default Contact;
