import React from 'react';
import { useParams } from 'react-router-dom';
import booksData from './booksData';
import './BookDetails.css';

const BookDetails = () => {
  const { bookIndex } = useParams();

  // Find the book based on bookIndex
  const book = booksData.flatMap((series) => series.books).find((book) => book.id === parseInt(bookIndex));

  if (!book) {
    return <div>Book not found</div>;
  }

  return (
    <div className="book-details-container">
      <div className={`book-details ${book.currentStatus ? 'three-cols' : 'two-cols'}`}>
        <img src={book.cover} alt={book.title} className="book-cover" />
        <div className="book-description-container">
          <h2 className="book-title">{book.title}</h2>
          <div
            className="book-long-description"
            dangerouslySetInnerHTML={{ __html: book.longDescription }}
          />
          {book.showBuyButton && (
            <a href={book.buyLink} className="book-buy-button" target="_blank" rel="noopener noreferrer">
              {book.buyButtonText}
            </a>
          )}
        </div>
        {book.currentStatus && (
          <div className="book-status-container">
            <h3 className="status-heading">Current Book Status</h3>
            <div
              className="book-current-status"
              dangerouslySetInnerHTML={{ __html: book.currentStatus }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default BookDetails;
