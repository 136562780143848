import React from 'react';
import questionsData from './questions.json';
import './About.css';

const About = () => {
  return (
    <div className="about">
      <div className="about-container">
        <h1 className="about-title">About Me</h1>
        <div className="special-note">
          <p>
            I asked ChatGPT to come up with a bunch of questions about my normal life, my writing life, and other random questions.
            This is what it came up with.
          </p>
        </div>
        {questionsData.categories.map((category, index) => (
          <div key={index} className="category">
            <h2 className="category-title">{category.title}</h2>
            {category.questions.map((question, index) => (
              <div key={index} className="question">
                <h3 className="question-title">{question.question}</h3>
                <p className="question-answer">{question.answer}</p>
              </div>
            ))}
            {index < questionsData.categories.length - 1 && <div className="category-space" />}
          </div>
        ))}
      </div>
    </div>
  );
};

export default About;
