import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <h1>Privacy Policy</h1>
      <p>
        By using the jerryevanoff.com website, you agree to follow the conditions outlined in this Privacy Policy, which
        was created to inform you about the data we collect from you. It also explains when and how this information can
        be used or disclosed.
      </p>
      <h2>Information Collected from Users</h2>
      <p>
        When filling out a form on the site, you may be asked to provide your contact information. This personal
        information will only be used by Jerry Evanoff to contact you as per your request and it will not be transferred,
        sold, or provided to any other party unless required by law. You have the right to not provide your contact
        information, but by declining you may not be able to submit the contact form or use the services or features
        offered on this site. This information is stored in our database on our server indefinitely (or until manually
        deleted).
      </p>
      <p>
        If you subscribe to Jerry Evanoff’s newsletter, your name and email address will be saved on mailerlite’s secure
        servers. We will only email you to send you news about Jerry Evanoff’s latest publications and/or to offer free,
        discounted, or newly released books from other authors you may enjoy. We do not share our newsletter subscriber
        list with other authors or marketing firms. Email information collected when you subscribed to Jerry Evanoff’s
        newsletter will remain on mailerlite’s server indefinitely so that your subscription preferences (including the
        date when you subscribed and unsubscribed, etc.) can be recorded.
      </p>
      <p>
        A Facebook pixel appears on every page of this site to help us determine a marketing audience and to track the
        success of Facebook advertising campaigns. Email addresses collected by Jerry Evanoff can, at times, be uploaded
        collectively to Facebook in order for its algorithms to create a “look-alike” group of similar Facebook users who
        may also be interested in Jerry Evanoff’s books. Individual email addresses are not used to target individual
        users on Facebook. The creation of “look-alike audiences” is a common practice used by Facebook advertisers when
        trying to expand their organic reach. It does not give Facebook the right to contact you using the email addresses
        that were uploaded.
      </p>
      <p>
        If you leave a book review or any other comment for other readers to see on this site or any other web page about
        Jerry Evanoff’s book(s), your comments may be used and posted by Jerry Evanoff without restriction and free of
        charge on our website and/or as deemed appropriate by Jerry Evanoff in other marketing efforts. You can request to
        have these comments or reviews deleted at any time by contacting Jerry Evanoff by email at jerry@jerryevanoff.com.
      </p>
      <h2>reCAPTCHA and Cookies</h2>
      <p>
        We have implemented reCAPTCHA v3 on our site and your use of reCAPTCHA v3 is subject to Google’s Privacy Policy
        and Terms of Use. reCAPTCHA is used to fight spam and abuse on our site. reCAPTCHA works by collecting hardware
        and software information, such as device and application data, and sending these data to Google for analysis. The
        information collected in connection with your use of the service is used for improving reCAPTCHA and for general
        security purposes. It is not used for personalized advertising by Google.
      </p>
      <p>
        Google Analytics tracking code is included on each page of this website to determine which pages are viewed and
        for how long. This website uses cookies from Facebook.com, Google.com, and our own domain name to keep track of
        various things. Data tracked by one or more of these domains include browsing behavior and traffic information
        such as URL, ISP, IP addresses, entry page, exit page, and other statistical, demographic or aggregated
        information from its visitors. This non-personal information cannot be linked to your personal data but can be
        used to improve this website and the information it contains. You can always change your browser configuration if
        you do not want to accept cookies from this site although it could result in problematic viewing of the site. Read
        how to clear your cookies  <a href="https://www.digitaltrends.com/computing/how-to-delete-cookies/" target="_blank" rel="noopener noreferrer">here</a>.
      </p>
      <h2>Affiliate Links</h2>
      <p>
        The site contains affiliate links for companies we use, trust, and recommend, such as Amazon, Apple Books, Kobo,
        and other booksellers. If you click on one of these affiliate links, a cookie will be placed on your computer and,
        should you make a purchase on that third-party website, Jerry Evanoff will be paid a small commission. You will
        not pay a penny more by going through these links. These affiliate links are carefully selected based on our
        experience with these service providers, and the small commission earned helps to cover the costs associated with this website and other advertising costs.
      </p>
      <h2>Limitation on Liability</h2>
      <p>
        By using and viewing this site, you acknowledge that Jerry Evanoff specifically disclaims any contractual, tort, strict, or any other type of liability for any direct, indirect, incidental, consequential, punitive, or any other type of damage associated with this site, including but not limited to, any virus that may infect your computer. All efforts are made to ensure such consequences don’t occur but you are held solely responsible for any damage related to downloading material obtained from this site or from sites linked to this site.
      </p>
      <p>
        Jerry Evanoff will not be held liable for the unavailability or failure of site functions except to the extent required by applicable laws. This site will contain links to other sites and other sites may link to this site. These links are added for pure convenience, and Jerry Evanoff is not responsible for the accuracy, reliability, or completeness of the content from these others sites. Accessing these sites is done at your own risk.
      </p>
      <h2>Limitation on Your Usage</h2>
      <p>
        You cannot copy, modify, distribute, license, disseminate, display, reproduce, transfer, sell, or offer to sell any material, information, product, image, text, code, or service viewed or obtained from this site without written permission from Jerry Evanoff.
      </p>
      <h2>Requesting More Information</h2>
      <p>
        Should you require clarification on this Privacy Policy, want to know the exact personal information that has been collected about you, or need your private information deleted from our database, you can contact Jerry Evanoff <a href="mailto:jerry@jerryevanoff.com">here</a> or via email at jerry@jerryevanoff.com.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
