import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; // or 'react-router-dom'
import './SouthPlainfieldHerald.css';

const articles = {
  '10-25-2022': {
    title: 'Tragedy Strikes at Morello Manufacturing: Plant Manager Found Dead',
    byline: 'By Jane Doe, Staff Writer',
    content: `In a shocking turn of events, Curtis Maddox, the recently appointed plant manager of Morello Manufacturing's largest hose production facility in Northeastern Wyoming, was found dead late Sunday night. Maddox, known for his hardline management style and cost-cutting measures, was discovered in his office by employees.

    According to sources within the company, Maddox had held a meeting with his staff earlier in the evening, announcing further controversial changes to the plant's operations. These included the removal of large floor fans from workstations and the commencement of a system upgrade at midnight, much to the dismay of the staff.

    Employees described the meeting as tense, with notable confrontations between Maddox and several workers. Among them, Reggie Taft, a long-time floor worker, voiced concerns over the removal of fans, especially near the plant's ovens. Another employee, Danielle Kaplan, had a heated exchange with Maddox over her shifting work schedule.

    Maddox, whose leadership style had been under scrutiny since his appointment, reportedly ended the meeting by instructing his staff to avoid disturbing him as he planned to review vendor payments.

    Law enforcement officials have not released details regarding the cause of death or any suspects. However, the incident has sent shockwaves through the small community, with employees and residents expressing disbelief and grief.

    Morello Manufacturing has not issued an official statement, but a company spokesperson indicated that counseling services would be made available to employees. The investigation is ongoing, and authorities are urging anyone with information to come forward.

    The tragic event raises questions about workplace dynamics and the pressures faced by employees in high-stress environments. Maddox's death marks a grim chapter for Morello Manufacturing, leaving a community in mourning and a company grappling with the aftermath of a night that will not soon be forgotten.`,
  },
  '10-27-2022': {
    title: 'Investigation Deepens into Maddox\'s Death Continues',
    byline: 'By Jane Doe, Staff Writer',
    content: `The mysterious death of Curtis Maddox, the plant manager of Morello Manufacturing in Northeastern Wyoming, continues to perplex law enforcement officials and community members alike. Detective Chase and her partner, Detective Emerson, are at the forefront of an investigation that grows more complex by the day.

    Detective Chase, maintaining a composed demeanor, stated, "We are rigorously pursuing all leads to uncover the truth behind Mr. Maddox's untimely death. While we understand the community's concern, we ask for patience as our investigation is still in its early stages."

    Contrastingly, Detective Emerson's approach was noticeably more direct. "We're turning this place inside out to find answers. No stone will be left unturned," he asserted, reflecting a determination to solve the mystery. However, when pressed for more details, Emerson remained tight-lipped, mirroring his partner's discretion.

    Rumors have circulated among Morello Manufacturing employees about one of their own working closely with the police. An anonymous source claimed, "It's like something out of a detective novel. One of our own is helping the police to solve Maddox's murder." However, when confronted with this information, Detective Emerson was quick to dismiss it. "We work with facts, not fiction. Speculations about internal collaborations are unfounded and unhelpful to our investigation," he responded gruffly.

    The death of Maddox has sent shockwaves through the small community, with many expressing disbelief and grief. The circumstances surrounding his demise remain shrouded in mystery, raising questions about the safety and dynamics within the manufacturing plant.

    Detective Chase, known for her thorough investigative methods, emphasized the importance of meticulous police work. "In cases like these, every detail matters. Our focus is on gathering concrete evidence and putting together the pieces of this puzzle," she explained, underscoring her commitment to the integrity of the investigation.

    Morello Manufacturing has remained operational amidst the ongoing investigation, with company officials ensuring that employee safety and wellbeing remain a top priority. The spokesperson for Morello Manufacturing declined to comment on the ongoing investigation but reassured that the company is fully cooperating with law enforcement.

    As the investigation continues, the South Plainfield Herald will provide updates on any new developments. Community members and Morello employees alike hope for a resolution to the unsettling events that have unfolded in their midst.`
  },
  '10-28-2022': {
    title: 'South Plainfield Tigers Stage Thrilling Comeback',
    byline: 'By Michael Thompson, Sports Writer for the South Plainfield Herald',
    content: `In an electrifying display of grit and determination, the South Plainfield High School Tigers football team clinched a remarkable victory against their long-time rivals, the Eastwood Eagles, this past Friday night. Overcoming a two-score deficit, the Tigers roared back in the final quarter to secure a memorable win.

    Junior quarterback Tyler Henderson had an outstanding game, throwing for 250 yards with two touchdowns, including a stunning 35-yard touchdown pass to wide receiver Marcus Johnson, who racked up 110 receiving yards on the night. This crucial play cut the deficit to just one score and ignited the Tigers' comeback.

    "The energy on the field was electric," said Coach Bill Simmons. "Tyler and Marcus connected perfectly, and it was just the momentum shift we needed."

    The Tigers' defense, which had struggled earlier in the game, stepped up to thwart the Eagles' advance, setting the stage for a dramatic finish. With only two minutes left on the clock, running back Chris "Lightning" Lewis darted through the Eagles' defense to score a 20-yard touchdown. Lewis finished the game with 85 rushing yards and the decisive touchdown, putting the Tigers ahead for the first time in the game.

    "The crowd went wild," remarked local fan and South Plainfield resident, Linda Martinez. "Chris's touchdown was a thing of beauty - pure athleticism and heart. It's a play we'll be talking about for years."

    Coach Simmons praised Lewis for his game-winning play. "Chris has the heart of a tiger. His speed and agility were crucial for us. But this was a team victory, and every player contributed to this unforgettable win."

    The Tigers' victory has not only bolstered their standing in the league but has also served as a beacon of hope and excitement for the South Plainfield community, especially in light of recent events at Morello Manufacturing. "This win is for the community. We needed this lift," Coach Simmons added.

    With the season progressing, the South Plainfield Tigers have shown they are a force to be reckoned with, and their recent triumph over the Eastwood Eagles will undoubtedly go down as one of the most thrilling comebacks in the school's history.

    The South Plainfield Tigers look forward to carrying this momentum into their upcoming games, with the community's support stronger than ever.`
  },
  '10-29-2022': {
    title: 'Second Murder Shocks Morello Manufacturing',
    byline: 'By Jane Doe, Staff Writer',
    content: `In an alarming development at Morello Manufacturing, Rich, a key figure in the company's operations, was found dead in his office, only days after the tragic death of plant manager Curtis Maddox. The body was discovered with a knife lodged in the neck, sending new waves of fear and uncertainty through the company.

    Sources within the police department, speaking on the condition of anonymity, revealed that the murder scene presented a complex puzzle. A peculiar arrangement of items was found around Rich's body, including a twenty-dollar bill clutched in his hand, secured by a rubber band. Law enforcement is scrutinizing this unusual detail, with theories suggesting it might be a staged element by the murderer.

    In a surprising twist, a gun, reportedly similar to the weapon used in Maddox's murder, was found under Rich's desk. This discovery has fueled speculation about a connection between the two deaths. Detective Chase, leading the investigation, stated, "We are exploring all angles, including the possibility of a link between the two incidents. However, we urge the public not to jump to conclusions as our investigation is ongoing."

    One employee, who requested anonymity, shared a controversial theory: "The murders seem too calculated, almost like someone's trying to throw the police off track. It's like they're staging a crime scene from a TV show." This comment aligns with observations made by Sam, another employee, who noted inconsistencies at the crime scene, such as Rich's mouse being on the wrong side of the desk, indicating potential staging.

    In contrast, another source close to the investigation, possibly misinformed, suggested the involvement of a romantic angle in the murders, pointing to alleged relationships within the company. However, this claim lacks substantial evidence and is treated with skepticism by officials.

    The police have not officially named any suspects, but the presence of perfume at the crime scene has led to rampant speculation among employees. Detective Emerson, part of the investigating team, commented, "We're considering every piece of evidence, no matter how small or seemingly insignificant."

    The back-to-back murders at Morello Manufacturing have left the community reeling, with many employees expressing concerns about their safety. The company spokesperson stated that they are cooperating fully with the investigation and have offered counseling services to all employees affected by these tragic events.

    As the police continue to unravel the mystery, the atmosphere at Morello Manufacturing remains tense, with employees hoping for a swift resolution to these horrifying crimes.`
  },
  '11-01-2022': {
      title: 'Young Innovators: South Plainfield High School\'s Science Fair Winners',
      byline: 'By Aaron Patterson, Education Correspondent',
      content: `The halls of South Plainfield High School buzzed with excitement as the annual Science Fair showcased a remarkable array of student projects. This year's fair, themed 'Innovations for a Better Tomorrow,' saw a record number of participants, with projects ranging from environmental solutions to technological advancements.
    
      Among the standout winners was sophomore Lily Zhang, whose project on biodegradable plastics impressed the judges with its practical application and thorough research. "I wanted to create something that could really make a difference in how we view everyday materials," Zhang said.
    
      Another highlight was the robotics project by junior Thomas Reid, which featured a robot capable of assisting in household tasks. Reid's project not only demonstrated technical prowess but also addressed the growing need for automation in everyday life.
    
      Senior Anita Desai received top honors for her innovative water purification system, designed to be both cost-effective and efficient in providing clean water in underserved communities. "My goal was to merge science with social impact. This project has been an incredible journey of learning and discovery," Desai shared.
    
      Principal Angela Richardson commended the students for their hard work and creativity: "Our students have gone above and beyond to address real-world problems through scientific inquiry. They are a testament to the bright future of innovation."
    
      The South Plainfield High School Science Fair has become a beacon of student achievement, inspiring future scientists and innovators to explore their potential and contribute to the world around them.`
    },
  '11-03-2022': {
    title: 'Letter to the Editor: Disappointment in Police Investigation',
    byline: 'By Margaret Jensen, South Plainfield Resident',
    content: `As a long-time resident of South Plainfield, I've always taken pride in our community and the institutions that serve us. However, the recent events at Morello Manufacturing have cast a shadow over our town, and I find myself deeply disappointed in the slow progress of the police investigation.

    The murders of Curtis Maddox and Richard Payne have not only been a tragedy for the families involved but have also sent ripples of fear and uncertainty throughout our community. We looked to our law enforcement for swift action and answers, yet weeks have passed with more questions than resolutions.

    The initial arrest of Danielle Kaplan, which now seems to be a dubious decision, raises concerns about the efficacy of the investigation. How could the police have been so certain, only to have their case turned on its head by an outsider with no formal training in law enforcement?

    It's disheartening to witness the growing mistrust among my fellow residents towards those sworn to protect us. We deserve transparency and efficiency from our police force, and I implore our law enforcement officials to redouble their efforts. Our community needs closure and justice for the victims and their families.

    We understand the complexity of such cases, but it's crucial that justice is not only done but seen to be done. We hope for a speedy resolution to this case and a restoration of faith in our law enforcement.

    Sincerely,
    Margaret Jensen`
  },
  '11-05-2022': {
    title: 'Danielle Kaplan Poisoned and Arrested in Connection with Morello Manufacturing Murders',
    byline: 'By Jane Doe, Staff Writer',
    content: `In a dramatic turn of events, Danielle Kaplan, an employee at Morello Manufacturing, was hospitalized for an apparent suicide attempt and subsequently arrested in connection with the recent murders at the manufacturing plant. Kaplan, found unconscious at her home, is alleged to have left a confession note implicating herself in the deaths of Curtis Maddox and Richard Payne, key figures at Morello Manufacturing.

    Detective Chase, maintaining her characteristic professionalism, stated, "Our investigation led us to Danielle Kaplan. Although we cannot divulge specifics at this time, we believe we have substantial evidence linking her to the crimes."

    In contrast, Detective Emerson provided a more blunt assessment. "We've got her, and we've got the evidence. Money, a confession note – it's a clear-cut case," he said. However, Emerson denied rumors of an internal informant within the plant, labeling such claims as "baseless and distracting from the real investigation."

    The arrest followed a series of bizarre events, including Kaplan's hospitalization due to ingesting a household cleaner. A source close to the investigation, requesting anonymity, claimed Kaplan's actions were an attempt at suicide. "It's a tragic turn for someone so deeply involved in this case," the source said.

    However, several employees at Morello Manufacturing expressed disbelief at Kaplan's involvement. One employee, who preferred to remain unnamed, stated, "Danielle was always kind and dedicated. It's hard to believe she could be involved in something like this."

    Emerson, addressing these doubts, reiterated, "Our job is to follow the evidence, not speculation or sentiment. The evidence we have points to Kaplan, and that's what matters in our investigation."

    Adding to the complexity, another anonymous source claimed Kaplan had been framed, suggesting that the evidence against her might have been manipulated. Emerson dismissed these claims as mere conspiracy theories.

    Kaplan's arrest has raised more questions than answers, with many in the community struggling to reconcile the image of a dedicated employee and mother with that of a suspected double murderer. "It's a situation that's hard to wrap your head around," one of Kaplan's neighbors commented.

    The police have assured the public that they are continuing their investigation to ensure all aspects of the case are thoroughly examined. Detective Chase emphasized the importance of due process: "We are committed to ensuring that justice is served, and that means examining every piece of evidence carefully."

    As Kaplan remains hospitalized, the community awaits further developments in this perplexing case that has gripped Morello Manufacturing and the town of South Plainfield.`
  },
  '11-08-2022': {
    title: 'Stunning Resolution in Morello Manufacturing Case',
    byline: 'By Jane Doe, Staff Writer',
    content: `In a startling development at Morello Manufacturing, the murders of Curtis Maddox and Richard Payne have finally been solved, revealing a conspiracy that few could have imagined. The real perpetrators, Benjamen Stanley and Kay Whitfield, were arrested following a comprehensive expose led by Sam, an IT specialist from Georgia.

    The revelation was made in a tense gathering of Morello Manufacturing employees, where Sam meticulously outlined the involvement of Benjamen and Kay in the embezzlement scheme and subsequent murders. This breakthrough contradicts earlier beliefs about the case and has shocked the local community and Morello employees alike.

    Detective Emerson, initially dismissive of Sam's involvement, had to concede to the new evidence. "We were on the wrong trail," admitted Emerson. "This case is a stark reminder that sometimes, the truth is stranger than fiction."

    Detective Chase, who has been closely monitoring the developments, expressed her astonishment at the unexpected turn. "The intricacy and depth of this case were unprecedented," she said. "We're grateful that the truth has finally come to light."

    According to sources present at the revelation, Benjamen Stanley orchestrated a complex embezzlement plot, enlisting Kay Whitfield to assist in eliminating anyone who threatened to expose their crimes. The evidence presented by Sam, including security footage and financial records, irrefutably implicated the duo.

    Christine Gibbs, an employee who witnessed the unfolding of events, said, "It's been an unbelievable experience. Ben and Kay were the last people we suspected. It's like living in a crime thriller."

    Another source, claiming to have insider knowledge, suggested the presence of a police informant within the plant. However, this was refuted by Detective Emerson, who emphasized the role of solid detective work in solving the case.

    The revelation has provided much-needed closure to the employees of Morello Manufacturing. "Finally, we can begin to heal and move forward," remarked one relieved worker.

    The apprehension of Benjamen and Kay Whitfield has prompted Morello Manufacturing to reassess their internal procedures to prevent similar incidents in the future. The company's spokesperson assured that measures are being taken to enhance security and oversight.

    With the real culprits behind bars, the community hopes to find peace and recovery from this tumultuous chapter in Morello Manufacturing's history.`
  },
  '11-09-2022': {
      title: 'Jazz in the Park: South Plainfield\'s Annual Music Festival',
      byline: 'By Aaron Patterson, Entertainment Reporter',
      content: `As the community continues to find solace and healing after the recent events at Morello Manufacturing, the much-anticipated South Plainfield Annual Jazz Festival offers a reason to come together in celebration and unity. This year's festival, set in the serene backdrop of Central Park, promises an array of talent and soulful music.
    
      Headlining the event is the renowned jazz saxophonist, Michael Andrews, known for his fusion of traditional jazz with modern rhythms. "Music has the power to heal and bring people together, and that's what we aim to do at this festival," Andrews stated.
    
      Local favorite, The Jazz Messengers, will also grace the stage, bringing their unique blend of classic and contemporary jazz tunes. Band leader Emma Thompson shared, "We're honored to be part of an event that holds such significance for our community, especially at a time when unity and togetherness are more important than ever."
    
      Community leader James Peterson remarked, "This festival is a testament to the resilience and spirit of South Plainfield. It's a chance for us to gather, enjoy great music, and remember that even in tough times, we have much to be thankful for."
    
      The South Plainfield Annual Jazz Festival has become a staple event, drawing visitors from neighboring towns and nurturing a growing jazz scene in the area. This year's festival not only promises great music but also stands as a beacon of hope and collective strength for the community.`
    },
};

const getSeasonalWeather = () => {
  const month = new Date().getMonth();
  const weatherConditions = {
    winter: ['Snowy', 'Blizzard', 'Cold', 'Foggy'],
    spring: ['Rainy', 'Windy', 'Mild', 'Cloudy'],
    summer: ['Sunny', 'Hot', 'Warm', 'Clear'],
    autumn: ['Chilly', 'Windy', 'Overcast', 'Rain']
  };

  let season;
  let temperatureRange;
  if (month < 2 || month === 11) {
    season = 'winter';
    temperatureRange = { min: 0, max: 40 };
  } else if (month < 5) {
    season = 'spring';
    temperatureRange = { min: 40, max: 60 };
  } else if (month < 8) {
    season = 'summer';
    temperatureRange = { min: 60, max: 80 };
  } else {
    season = 'autumn';
    temperatureRange = { min: 40, max: 60 };
  }

  const temperature = Math.floor(Math.random() * (temperatureRange.max - temperatureRange.min + 1)) + temperatureRange.min;
  const randomWeather = weatherConditions[season][Math.floor(Math.random() * weatherConditions[season].length)];
  
  return `${randomWeather}, ${temperature}°F`;
};

const SouthPlainfieldHerald = () => {
  const [selectedArticleDate, setSelectedArticleDate] = useState('10-25-2022');
  const [weather, setWeather] = useState('');
  const selectedArticle = articles[selectedArticleDate];

  useEffect(() => {
    setWeather(getSeasonalWeather());
  }, []);

  const renderParagraphs = (content) => {
    return content.split('\n').map((paragraph, index) => (
      <p key={index}>{paragraph}</p>
    ));
  };

  return (
    <div className="herald">
      <header className="herald-header">
        <div className="header-section left">
          <p className="date-weather">11/10/2022 &#8226; {weather}</p>
        </div>
        <div className="header-section center">
          <h1>South Plainfield Herald - Archives</h1>
        </div>
        <div className="header-section right">
          <a href="https://dl.bookfunnel.com/h0rimhe1qd" className="subscribe-button" target="_blank">Subscribe</a>
        </div>
      </header>
      <div className="herald-content">
        <aside className="herald-sidebar">
          {Object.keys(articles).map(date => (
            <p key={date} onClick={() => setSelectedArticleDate(date)}>
              {date}: {articles[date].title} 
            </p>
          ))}
        </aside>
        <main className="herald-article">
          <h2>{selectedArticle.title}</h2>
          <p className="byline">{selectedArticle.byline}</p>
          {renderParagraphs(selectedArticle.content)}
        </main>
      </div>
    </div>
  );
};

export default SouthPlainfieldHerald;