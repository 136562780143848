import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './Components/Header';
import Hero from './Components/Hero';
import Books from './Components/Books';
import About from './Components/About';
import BookDetails from './Components/BookDetails';
import Menu from './Components/Menu';
import Contact from './Components/Contact';
import ExternalRedirect from './ExternalRedirect';
import Footer from './Components/Footer';
import PrivacyPolicy from './Components/PrivacyPolicy';
import SouthPlainfieldHerald from './Components/SouthPlainfieldHerald'; 

function App() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [category, setCategory] = useState('All Books');

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleCategoryChange = (newCategory) => {
    setCategory(newCategory);
  };

  return (
    <Router>
      <div className="App" style={{ width: '100%', maxWidth: '100vw', margin: 0, padding: 0 }}>
        <Header toggleMenu={toggleMenu} />
        <Menu isOpen={isMenuOpen} handleCategoryChange={handleCategoryChange} />
        <Routes>
          <Route path="/" element={<Hero />} />
          <Route path="/books/*" element={<Books category={category} />} />
          <Route path="/about" element={<About />} />
          <Route path="/podcast" element={<ExternalRedirect url="https://podcasters.spotify.com/pod/show/jerry-evanoff2" />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/time-travel" element={<Books category="timeTravel" />} />
          <Route path="/murder-mystery" element={<Books category="murderMystery" />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/bookdetails/:bookIndex" element={<BookDetails />} />
          <Route path="/southplainfieldherald" element={<SouthPlainfieldHerald />} /> {/* New route */}
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
