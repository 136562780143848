const booksData = [
  {
    seriesName: 'Sam Norris Murder Mysteries',
    books: [
      {
        id: 1,
        seriesName: 'SNMM',
        bookNumber: 1,
        title: 'Manufacturing Murder',
        cover: '/images/Evanoff_ManufacturingMurder_Ebook.jpg',
        description: 'Meet Sam Norris, a software developer whose latest creation for a midsize company promises to revolutionize their manufacturing plants.',
        longDescription: 'A routine software installation turns into a race against time and death...<br><br>Meet Sam Norris, a software developer whose latest creation for a midsize company promises to revolutionize their manufacturing plants. He and his team travel to a remote city in Wyoming to implement the new system, but what they find is far from ordinary.<br><br><b>When the plant manager turns up dead, a whirlwind of suspects with hidden motives surfaces.</b><br><br> Amidst the chaos, Sam crosses paths with Dani, a night shift worker at the plant. Drawn to her magnetic presence, they forge a connection even as she becomes the prime suspect in the eyes of the police. As Sam grapples with his own secrets, he\'s determined to uncover the truth and clear Dani\'s name.<br><br><b>But when a second body is discovered, the stakes are raised even higher.</b><br><br>Can Sam solve the murders and save Dani from being wrongly accused? Will he be able to stop the killer before becoming the next victim?<br><br>Dive into this gripping murder mystery filled with unexpected twists, nail-biting cliffhangers, and a plot that will keep you turning pages well into the night.<br><br><b>Don\'t wait – immerse yourself in Manufacturing Murder now!</b>',
        currentStatus: '',
        heroDescription: 'Manufacturing Murder: A gripping murder mystery filled with unexpected twists, nail-biting suspense, and a race against time. Join software developer Sam Norris on a routine installation that turns deadly. When the plant manager is found dead, Sam teams up with Dani, a night shift worker and prime suspect, to uncover the truth. As the body count rises, can Sam solve the murders and save Dani from being wrongly accused? Dive into this thrilling page-turner and unravel the secrets before it\'s too late.',
        freeGiveawayDescripiton: '',
        buyLink: 'https://www.amazon.com/dp/B0C1W9D7CS?maas=maas_adg_08D9EE5F6CC854C4FF53B35156191C8B_afap_abs&ref_=aa_maas&tag=maas',
        buyButtonText: 'More Info',
        showBuyButton: true,
        paperbackBuyButtonText: 'Paperback',
        showPaperbackBuyButton: true,
        showBook: true,
        category: 'murderMystery',
        readingOrder: '1',
        readingOrderDescription: 'Meet the characters and ride with Peter as he moves through his first adventure.',
        showInHero: true,
        showInFreeGiveaway: false,
        progress: 100,
        heroOrder: '1',
      },
      {
        id: 2,
        seriesName: 'SNMM',
        bookNumber: 2,
        title: 'Inheriting Murder',
        cover: '/images/Inheriting-Murder_cvr-v2c.jpg',
        description: 'Coming Fall 2024',
        longDescription: 'The reading of a will is a sad occasion for everyone…<br><br>but it should never end in death.<br><br>Fresh from solving his first mystery in South Plainfield, Sam Norris returns with newfound confidence and the spark of a budding romance.<br><br><b>Life seems to be falling into place for Sam until devastating news arrives:</b><br><br>Emmitt Layne, a father-figure in his life, is dead. Emmitt wasn\'t just Sam\'s rock. He was also the father of his best friend, Carey. But the will reading didn\'t go as planned and someone died.<br><br><b>After a second murder. Carey is thrown into jail, accused of both murders.</b><br><br>Now Sam is stuck in a tight spot, torn between exonerating his best friend and while also delving into a cold case that his other friends are begging him to solve.<br><br><b>Then a shocking find is made.</b><br><br>Can Sam navigate this labyrinth of mysteries to free Carey from the shackles of a crime he didn\'t commit? Can he shine a light on the truth veiled in the shadowy corners of the cold case?<br><br>This engaging murder mystery draws you in with its measured twists and strategic turns. The intrigue carefully built into each chapter\'s conclusion will keep you absorbed, gently urging you to turn the pages as the night quietly unfolds.',
        heroDescription: '',
        currentStatus: 'About 90% finished. Needs a final edit, then it\'s off to the editor.<br><br>I\'ve also plotted out the next 7 books in the series and I\'m working hard in 2024 to write as many as possible.',
        freeGiveawayDescripiton: '',
        buyLink: '',
        buyButtonText: 'More Info',
        showBuyButton: false,
        paperbackBuyButtonText: 'Paperback',
        showPaperbackBuyButton: false,
        showBook: true,
        category: 'murderMystery',
        readingOrder: '3',
        readingOrderDescription: '',
        showInHero: false,
        showInFreeGiveaway: false,
        progress: 90,
        heroOrder: '1',
      },
    ],
  },
  {
    seriesName: 'Sam Norris Short Stories',
    books: [
      {
        id: 3,
        seriesName: 'SNMM',
        bookNumber: 1,
        title: 'The Ledger',
        cover: '/images/Ledger-Kindle-High-Resolution.jpg',
        shortDescription: '',
        description: 'Short Story that happens between Manufacturing Murder and Inheriting Murder',
        longDescription: 'In the heart of Savannah, Sam and Dani race against time to uncover the truth behind a mysterious ledger that could give Sam the proof he needs to uncover the mystery of his parents\' death.<br><br>When they meet the elusive Fox, he sends down on a dangerous path filled with intrigue and deception.<br><br>As they delve deeper into the conspiracy, they realize that their own lives may be in danger. Will Sam find the ledger before his pursuers?<br><br>Will they be able to unravel the truth before it\'s too late?',
        currentStatus: '',
        heroDescription: '',
        freeGiveawayDescripiton: 'Nightmares Through Time',
        buyLink: 'https://BookHip.com/KXGLPWK',
        buyButtonText: 'Get Free!',
        showBuyButton: true,
        paperbackBuyButtonText: 'Paperback',
        showPaperbackBuyButton: false,
        showBook: true,
        category: 'murderMystery',
        readingOrderDescription: 'A short story showing what happened between Manufacturing Murder and Inheriting Murder.',
        readingOrder: '2',
        showInHero: false,
        showInFreeGiveaway: true,
        progress: 100,
        heroOrder: '1',
      },
    ],
  },
  {
    seriesName: 'Nightmares Through Time',
    books: [
      {
        id: 4,
        bookNumber: 1,
        seriesName: 'NTT',
        title: 'Forgetting Tomorrow',
        cover: '/images/Evanoff_ForgettingTomorrow_Ebook.jpg',
        description: 'Book 1 in the Nightmares Through Time series.',
        longDescription: '<b>Which door would you choose?</b><br><br>Peter lives in the world of IT. It’s not glamorous. He’s bored. At least he has Anna in graphic design. She makes coming to work tolerable.<br><br><b>How can he win her over?</b><br><br>At the end of each day, Peter heads home alone and sits in his living room, tinkering with his secret project. The day it worked, he discovered his future was full of death and destruction, possibly of his own doing. Would he regret his choice to use it?<br><br><b>After Anna finds out his secret, his worlds collide.</b><br><br>With a host of new possibilities, his life has changed forever, but the path he’s on isn’t heading where he thinks, and Peter will have to face the worst decision imaginable.<b>Will he choose to save Anna or humanity?</b><br><br>You’ll love this time-travel mystery adventure, because of the twists, turns, and an ending you’ll never guess.',
        currentStatus: '',
        heroDescription: '',
        freeGiveawayDescripiton: '',
        buyLink: 'https://www.amazon.com/dp/B07TXX6T1H?maas=maas_adg_8B3AFFE84C4AAB0E2300FE457F0651FD_afap_abs&ref_=aa_maas&tag=maas',
        buyButtonText: 'More Info',
        showBuyButton: true,
        paperbackBuyButtonText: 'Paperback',
        showPaperbackBuyButton: false,
        showBook: true,
        category: 'timeTravel',
        readingOrder: '1',
        showInHero: false,
        showInFreeGiveaway: false,
        progress: 100,
        heroOrder: '1',
      },
      {
        id: 5,
        seriesName: 'NTT',
        bookNumber: 2,
        title: 'Spark',
        cover: '/images/Evanoff_Spark_Ebook.jpg',
        description: 'Book 2 in the Nightmares Through Time series.',
        longDescription: '<b>She wanted to change the world</b><br><br>A near-fatal lightning strike ended her life.<br><br>When her conscience is pulled into a realm of death and destruction, she’ll get a second chance.<br><br>Everything was going well for Linda. She had just graduated from college, started a new job and met a man. One rainy evening, she is struck by lightning and sent into a coma, seemingly ending her life.><br><br><b>Until a mysterious voice calls out to her.</b><br><br>She follows the voice and is brought into the future, witnessing events before and after an incident that destroys the world. She is told she’s the chosen one, the one who will save everyone, but she can only do it by following a boy who holds the key to time travel. And she must do it while stuck inside the machinery.<br><br><b>But unbeknownst to Linda, enemies are gathering.</b><br><br>Can she stop them before they discover her secret? Can she keep them from harming her allies? Will she figure out the puzzle laid before her to save humanity?<br><br>Spark is the second book in the Nightmares Through Time science-fiction, time-travel and dystopian series. It features action-packed suspense with a twist that will keep you turning pages well into the night.',
        currentStatus: '',
        heroDescription: '',
        freeGiveawayDescripiton: '',
        buyLink: 'https://www.amazon.com/dp/B08CSXMJQ3?maas=maas_adg_121D54A9E1D717B4F67EC323D75B3F08_afap_abs&ref_=aa_maas&tag=maas',
        buyButtonText: 'More Info',
        showBuyButton: true,
        paperbackBuyButtonText: 'Paperback',
        showPaperbackBuyButton: false,
        showBook: true,
        category: 'timeTravel',
        readingOrder: '5',
        showInHero: false,
        showInFreeGiveaway: false,
        progress: 100,
        heroOrder: '1',
      },
      {
        id: 6,
        seriesName: 'NTT',
        bookNumber: 3,
        title: 'Revolution',
        cover: '/images/Evanoff_Revolution_Ebook.jpg',
        description: 'Book 3 in the Nightmares Through Time series. (IN PROGRESS)',
        longDescription: 'This book is currently in process.<br><br>It will follow the journey of Padma, her sister and their friends, as they do whatever it takes to stop their home from turning into a dysoptian society kept in check by a crazy despot with a murderous military at his command.<br><br>I\'m about 65% of the way through it, but I have put it aside to finish a few other things. I plan on picking it back up in 2024 to finish.',
        currentStatus: 'About 65% finished, but currently on hold while I work on the Sam Norris Series. It will be published at some point though.',
        heroDescription: '',
        freeGiveawayDescripiton: '',
        buyLink: '',
        buyButtonText: 'More Info',
        showBuyButton: false,
        paperbackBuyButtonText: 'Paperback',
        showPaperbackBuyButton: false,
        showBook: false,
        category: 'timeTravel',
        readingOrder: '6',
        showInHero: false,
        showInFreeGiveaway: false,
        progress: 0,
        heroOrder: '1',
      },
    ],
  },
  {
    seriesName: 'Nightmares Through Time Short Stories',
    books: [
      {
        id: 7,
        seriesName: 'NTT Short Stories',
        bookNumber: 1,
        title: 'Origins - Volume 1',
        cover: '/images/Origins-Volume1.jpg',
        description: 'Book One in the Prequel Trilogy Novella Series, a Companion Trilogy to Nightmares Through Time',
        longDescription: '<b>He wanted a second chance.<br><br>But it could cost him his life.</b><br><br>Peter, a mid-twenties IT tech at a small software company, had been studying the secret of time travel. But he had grown weary of the possibility that it would ever happen, and his life would continue down the lonesome, boring path it was on.<br><br><b>Then one night his machine worked.</b><br><br>After an embarrassing first meeting with Anna, a new graphic designer, he decided to use his machine to change things, learn about her and do it all over.<br><br><b>But he wasn\'t the only one studying the secret.</b><br><br>Will he make things right with Anna? Can he save his best friend Zach? Or will enemies lurking closer to Peter than he knows disrupt his plans and take him down?<br><br>You\'ll love this time travel adventure as is moves across multiple timelines, throws shocking twists at you and will keep you turning pages well into the night.<br><br><b>Get it now.</b>',
        currentStatus: '',
        heroDescription: '',
        freeGiveawayDescripiton: 'Sam Norris Murder Mysteries',
        buyLink: 'https://dl.bookfunnel.com/iwm87hwyth',
        buyButtonText: 'Get Free!',
        showBuyButton: true,
        paperbackBuyButtonText: 'Paperback',
        showPaperbackBuyButton: false,
        showBook: true,
        category: 'timeTravel',
        readingOrder: '2',
        showInHero: false,
        showInFreeGiveaway: true,
        progress: 100,
        heroOrder: '1',
      },
      {
        id: 8,
        seriesName: 'NTT Short Stories',
        bookNumber: 2,
        title: 'Origins - Volume 2',
        cover: '/images/Origins-Volume2.jpg',
        description: '(IN PROGRESS)',
        longDescription: 'This book is currently in process.</br></br>Peter is trapped with a surprising enemy in Montana in 1873. He must work with the townspeople of Clayborne, Montana to keep the town from being destroyed.',
        currentStatus: 'Currently in progress, about 10% finished.',
        heroDescription: '',
        freeGiveawayDescripiton: '',
        buyLink: 'https://example.com/book8-buy',
        buyButtonText: 'Buy Now',
        showBuyButton: false,
        paperbackBuyButtonText: 'Paperback',
        showPaperbackBuyButton: false,
        showBook: true,
        category: 'timeTravel',
        readingOrder: '3',
        showInHero: false,
        showInFreeGiveaway: false,
        progress: 0,
        heroOrder: '1',
      },
      {
        id: 9,
        title: 'Origins - Volume 3',
        seriesName: 'NTT Short Stories',
        bookNumber: 3,
        cover: '/images/Origins-Volume3.jpg',
        description: 'Book Nine Description',
        longDescription: 'This book should be available in 2024.',
        currentStatus: '',
        heroDescription: '',
        freeGiveawayDescripiton: '',
        buyLink: 'https://example.com/book9-buy',
        buyButtonText: 'Buy Now',
        showBuyButton: false,
        paperbackBuyButtonText: 'Paperback',
        showPaperbackBuyButton: false,
        showBook: true,
        category: 'timeTravel',
        readingOrder: '4',
        showInHero: false,
        showInFreeGiveaway: false,
        progress: 0,
        heroOrder: '1',
      },
    ],
  },
];

export default booksData;
