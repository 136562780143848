import React from 'react';
import './Hero.css';
import booksData from './booksData';
import { Link } from 'react-router-dom';

const Hero = () => {
  const heroBook = booksData.find(series => series.books.some(book => book.showInHero));
  const freeGiveawayBooks = booksData.filter(series => series.books.some(book => book.showInFreeGiveaway));

  if (!heroBook || freeGiveawayBooks.length < 2) {
    return null;
  }

  const heroBookCoverURL = heroBook.books[0].cover;
  const heroBookBuyLink = heroBook.books[0].buyLink;
  const heroBookBuyButtonText = heroBook.books[0].buyButtonText;
  const heroBookId = heroBook.books[0].id;

  const facebookLink = 'https://www.facebook.com/jerryevanoffauthor';

  // SEO meta tags
  const pageTitle = 'Jerry Evanoff - Author';
  const pageDescription = 'Discover the latest release and claim your free books from Jerry Evanoff, a talented author in the genre.';
  const pageImage = heroBookCoverURL; // Assuming this is the image URL for the latest release book cover

  const booksWithProgress = booksData
    .flatMap(series => series.books.filter(book => book.progress > 0 && book.progress < 100))
    .sort((a, b) => b.progress - a.progress);

  return (
    <>
      <head>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:image" content={pageImage} />
      </head>
      <section className="hero">
        <div className="hero-content">
          <div className="hero-left">
            <div className="hero-frame">
              <h1 className="hero-title">Latest Release</h1>
              <Link to={`/bookdetails/${heroBookId}`}>
                <img src={heroBookCoverURL} alt="Book Cover" className="hero-img" />
              </Link>
              <p>
                <a href={heroBookBuyLink} target="_blank" rel="noopener noreferrer" className="hero-button">
                  {heroBookBuyButtonText}
                </a>
              </p>
            </div>
          </div>
          <div className="hero-right">
            <a href={facebookLink} target="_blank" rel="noopener noreferrer" className="facebook-link">
              <div className="facebook-frame">Follow Me On Facebook</div>
            </a>
            <div className="giveaway-section">
              <h2 className="giveaway-label">Claim Your Free Books!</h2>
              <div className="giveaway-books">
                {freeGiveawayBooks.map((series, index) => {
                  const giveawayBook = series.books.find(book => book.showInFreeGiveaway);
                  return (
                    <div className="giveaway-book" key={index}>
                      <Link to={`/bookdetails/${giveawayBook.id}`}>
                        <img src={giveawayBook.cover} alt="Book Cover" className="giveaway-img" />
                      </Link>
                      <p className="giveaway-description">{giveawayBook.freeGiveawayDescription}</p>
                      <a href={giveawayBook.buyLink} target="_blank" rel="noopener noreferrer" className="giveaway-button">
                        {giveawayBook.buyButtonText}
                      </a>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="progress-chart">
              <h2 className="chart-title">UPCOMING BOOKS</h2>
              {booksWithProgress.map(book => (
                <div key={book.id} className="progress-item">
                  <div className="progress-info">
                    <span className="progress-title">{book.title} ({book.seriesName} - Book {book.bookNumber})</span>
                  </div>
                  <div className="progress-bar-container">
                    <div className="progress-bar" style={{ width: `${book.progress}%` }}>
                      <div className="progress-bar-fill"></div>
                    </div>
                    <div className="progress-number">{book.progress}%</div>
                  </div>
                </div>
                ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hero;
