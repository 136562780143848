import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import booksData from './booksData';
import './Books.css';

const Books = ({ category }) => {
  const [filteredBooks, setFilteredBooks] = useState([]);

  useEffect(() => {
    const filteredBooks = booksData.filter((series) => {
      const filteredSeries = {
        ...series,
        books: series.books.filter(
          (book) => book.showBook && (category === 'All Books' || book.category.toLowerCase() === category.toLowerCase())
        ),
      };
      return filteredSeries.books.length > 0;
    });

    setFilteredBooks(filteredBooks);
  }, [category]);

  const sortedReadingOrderBooks = filteredBooks
    .flatMap((series) => series.books)
    .filter((book) => book.readingOrder)
    .sort((a, b) => parseInt(a.readingOrder) - parseInt(b.readingOrder));

  const showReadingOrder = category !== 'All Books' && sortedReadingOrderBooks.length > 1;
  const showReadingOrderSection = showReadingOrder && filteredBooks.length > 1;

  // SEO metadata
  const pageTitle = 'Jerry Evanoff Author - Independent Author with Books in the Time Travel and Murder Mystery genres';
  const pageDescription =
    'Explore a diverse collection of books in different genres, including murder mystery and science fiction time travel. Jerry likes to write books in lots of genres and has plans for future books in the space opera and maybe even a Star Wars fanfiction coming next.';

  return (
    <section className="books">
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta
          name="keywords"
          content="books, reading, science fiction, time travel, murder mysteries, private detective, amateur sleuth"
        />
        <meta name="author" content="Jerry Evanoff" />
      </Helmet>

      <div className="books-container">
        <div className="my-books">
          {filteredBooks.map((series, seriesIndex) => (
            <div key={seriesIndex} className="books-series">
              <h3 className="series-title">{series.seriesName}</h3>
              <div className="books-row">
                {series.books.map((book, bookIndex) => (
                  <div key={bookIndex} className="book">
                    <Link to={`/bookdetails/${book.id}`}>
                      <img src={book.cover} alt={book.title} className="book-cover" />
                    </Link>
                    <h4 className="book-title">{book.title}</h4>
                    <p className="book-description">{book.shortDescription}</p>
                    {book.showBuyButton && (
                      <a href={book.buyLink} className="book-button" target="_blank" rel="noopener noreferrer">
                        {book.buyButtonText}
                      </a>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>

        {showReadingOrderSection && (
          <div className="reading-order">
            <h5 className="reading-order-label">Suggested Reading Order</h5>
            {sortedReadingOrderBooks.map((book, index) => (
              <div key={book.title} className="reading-order-item">
                <span className="reading-order-title">{index + 1}. <strong>{book.title}</strong></span>
                {book.readingOrderDescription && (
                  <p className="reading-order-description">{book.readingOrderDescription}</p>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </section>
  );
};

export default Books;
