import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faFacebook, faAmazon, faEtsy } from '@fortawesome/free-brands-svg-icons';
import './Menu.css';

const Menu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <section className={`menu-section ${isOpen ? 'open' : ''}`}>
      <div className="menu-toggle" onClick={toggleMenu}>
        <div className="menu-toggle-icon">
          <div className="menu-toggle-line"></div>
          <div className="menu-toggle-line"></div>
          <div className="menu-toggle-line"></div>
        </div>
        <span className="menu-toggle-text">Menu</span>
      </div>
      <ul className={`menu ${isOpen ? 'open' : ''}`}>
        <li className="menu-item">
          <Link to="/" className="menu-link" onClick={closeMenu}>
            Home
          </Link>
        </li>
        <li className="menu-item">
          <Link to="/books" className="menu-link" onClick={closeMenu}>
            All Books
          </Link>
        </li>
        <li className="menu-item">
          <Link to="/time-travel" className="menu-link" onClick={closeMenu}>
            Time Travel
          </Link>
        </li>
        <li className="menu-item">
          <Link to="/murder-mystery" className="menu-link" onClick={closeMenu}>
            Murder Mystery
          </Link>
        </li>
        <li className="menu-item">
          <a href="https://podcasters.spotify.com/pod/show/jerry-evanoff2" target="_blank" rel="noopener noreferrer" className="menu-link" onClick={closeMenu}>
            Podcast
          </a>
        </li>
        <li className="menu-item">
          <Link to="/about" className="menu-link" onClick={closeMenu}>
            About
          </Link>
        </li>
        <li className="menu-item">
          <Link to="/contact" className="menu-link" onClick={closeMenu}>
            Contact
          </Link>
        </li>
        <li className="menu-item menu-social-icons">
          <a href="https://twitter.com/jerrye25" target="_blank" rel="noopener noreferrer" className="social-icon">
            <FontAwesomeIcon icon={faTwitter} />
          </a>
          <a href="https://www.facebook.com/jerryevanoffauthor" target="_blank" rel="noopener noreferrer" className="social-icon">
            <FontAwesomeIcon icon={faFacebook} />
          </a>
          <a href="https://www.amazon.com/stores/Jerry-Evanoff/author/B07TSLN98V" target="_blank" rel="noopener noreferrer" className="social-icon">
            <FontAwesomeIcon icon={faAmazon} />
          </a>
          <a href="https://www.etsy.com/shop/JerryEvanoffAuthor" target="_blank" rel="noopener noreferrer" className="social-icon">
            <FontAwesomeIcon icon={faEtsy} />
          </a>
        </li>
      </ul>
    </section>
  );
};

export default Menu;
