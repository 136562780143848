import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

const Header = () => {
  return (
    <Link to="/" className="header-link">
      <header className="header">
        <div className="header-container">
          <div className="header-left">
            <div className="header-title">
              <h1>Jerry Evanoff</h1>
              <div className="header-title-spacing"></div>
              <h2>Author of Science Fiction and Mystery Books</h2>
            </div>
          </div>
          <div className="header-right">
            <div className="header-books">
              <div className="header-book book1"></div>
              <div className="header-book book2"></div>
              <div className="header-book book3"></div>
            </div>
          </div>
        </div>
      </header>
    </Link>
  );
};

export default Header;
